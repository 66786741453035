:root {
  --nav-color:#8b7748;
  --grid-bg : #675994bb;
  --pattern-tile-bg :#8b7748;
  --title-bg : #3809d2;
  --title-color : #fff;
  --input-border-color : #deb887;
  --option-background-color :#3809d2;
  --button-background : #ad00aa;
  --button-background-hover : #d305cf;
  --link-color : #c4e0e4;
  --footer-color : #333;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* general styles  */

button {
  padding: 6px;
  border: none;
  border-radius: 6px;
  background: var(--button-background);
  color: #fff;
}

button:hover {
  background: var(--button-background-hover);
  cursor: pointer;
}

button .btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

header {
  background: rgb(0,17,50);
  color:#fff;
  height: 12vh;
}

main {
  height: 84vh;
}

footer {
  height: 4vh;
  background-color: var(--footer-color);
  color: #fff;
}

img {
  width: 100%;
  height: 100%;
}

select {
  padding: 6px;
  outline: 2px solid var(--input-border-color);
  margin: 6px;
  cursor: pointer;
}

select::-ms-expand {
  cursor: pointer;
}

select option {
  background: var(--option-background-color);
  color: #fff;
  cursor: pointer;
}



/* home page  */
#home {
  height: 100%;
  padding: 10px;
}

.homepage-title {
  background: var(--title-bg);
  color: var(--title-color);
  width: 400px;
  padding: 6px;
  margin: 10px auto;
  border-radius: 10px;
}

.pattern-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--grid-bg);
  border-radius: 10px;
  padding: 10px;
  margin: auto 50px;
  height: 70%;
}

.pattern-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  cursor: pointer;
  color: #fff;
  justify-items: center;  /* Center items horizontally */
  align-items: center;  /* Center items vertically */
}

.pattern-grid .pattern-title {
  flex: none;
  height: 2rem;
  cursor: pointer;
  color: #000;
}

.pattern-grid .pattern-title h3{
  font-size: .75rem;
}

.pattern-grid .pattern-tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--pattern-tile-bg);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.page-selector {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
}

.pattern-img {
  width: 10rem;
  height: 10rem;
}

.pattern-img img{
  border-radius: 10px;
}

.category-selector {
  background-color: #8b7748;
  width: 300px;
  margin: 10px auto;
  border-radius: 10px;
}

@media(max-width: 930px) {
  .pattern-img {
    width: 8rem;
    height: 8rem;
  }
}

@media(max-width: 790px) {
  .pattern-container {
    width: 100%;
    margin: auto;
  }

  .pattern-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
  }

  .pattern-img {
    width: 100%;
    height: 100%;
  }

  .homepage-title {
    width: 100%;
  }

  .category-selector {
    width: 100%;
    padding-bottom: 10px;
  }

  .page-selector {
    width: 100%;
  }

  main {
    height: 100%;
    min-height: 84vh;
  }

  .sticky {
    position: fixed;
    width: 100%;
  }

  #home {
    min-height: 84vh;
  }
}


